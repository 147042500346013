// en, de, fr, pt
export default {
  en: {
    Dashboard: 'Dashboard',
    Utilisateurs: 'Users',
    Compagnies: 'Corporates',
    Address:'adress',
    Date_creation:'creation date',
    CFE :'CFE',
    Date_creation_error:'The creation date field is required',
    clients:'Clients',
    abonnements :'Subscriptions',
    ABONNEMENTS  :'SUBSCRIPTION',
    Age :'Age',

    Solutions: 'Solutions',
    Incidents : 'Incidents',
    Tickets_totaux: 'All tickets',
    Tickets_traité:'Processed tickets',
    Tickets_non_traités:'Unprocessed tickets',
    tickets_encours_traitement:'Being processed tickets ',
    En_cours_de_traitement:'Initiate treatment',
    Créer_un_ticket:'New ticket',
    Traiter:'Treaty',
    Traiter1:'Treaty',
    Non_Traité:'Untreated',
    urgency:'Urgency',
    no_urgency:'Not urgency',
    Profil: 'Profile',
    PROFIL: 'PROFILE',
    Nom_Prenons: 'full name',
    mail: 'Email',
    username:'username',
    Phone: 'Telephone',
    Compagnie: 'corporates',
    Ajouter: 'Add',
    AJOUTER_UN: 'ADD',
    AJOUTER_UNE: 'ADD',
    MODIFICATION_DE_L: 'EDIT',
    UTILISATEUR: 'USER',
    UTILISATEURS: 'Users',
    Modifier: 'Edit',
    Supprimer: 'Delete',
    Action: 'Action',
    Rôle: 'Role',
    Nom: 'Last Name',
    Prénoms: 'First names',
    Soumettre: 'Submit',
    Annuler: 'Cancel',
    enteteDelete: 'do you  want to delete',
    Confirmer :'comfirm',
    SuppressionUser1:'The user is successfully deleted.',
    
    Suppression:'deletion failed.',
    save_error:'Update failed.',
    save_sucess_user:'The user is save successfully.',
    update_user:'The user is changed successfully.',
    last_name_error:  'The last name field is required',
    first_name_error: 'The first names field is required',
    role_error:  'The role field is required',
    email_error: 'The email field is required',
    username_error: 'The username field is required',
    phoneNumber_error:'The phone number field is required',
    nom_error: 'The name field is required',
    SuppressionClient:'The client is deleted successfully.',
    Address_error:'The address field is required',
    nametype_error:'The incident Tickets',
    INCIDENT:'INCIDENT',
    incident:'Incidents',
    incident_update:'The incident is modified successfully.',
    incident_save:'The incident is save successfully.',
    Solution:'subscription',
    abonnement_delete: 'The subscription is deleted successfully.',
    SOLUTION:'SOLUTION',
    error_abonnement: 'The subscription field is required',
    solution_fiecfeld_error:'The subscription field is required',
    abonnement_save:'The subscription is saved successfully.',
    abonnement_update:'The subscription is modified successfully.',
    Company_delete :'The corporate is deleted successfully.',
    resource_person:'Resource person',
    CFE_error:'The resource CFE field is required',
    corporate_save: 'The corporate is saved successfully.',
    corporate_update : 'The corporate is modified successfully.',
    COMPAGNIE:'CORPORATE',
    non_lu : 'Unread',
    lu :'Read',
    actualiser:'Refresh',
    voir:'View',
    Envoyer_un_mail:'Send email',
    Envoyer:'Send',
    Destinataire:'Recipient',
    créer_un_ticket:'New ticket',
    ticket_error:'Your registration failed.',
    ticket_succes:'The ticket is created successfully.',
    Précédent:'Previous',
    Suivant:'Next',
    Annuler_le_traitement_encours:'Cancel processing in progress',
    Mettre_encours_de_traitement:'Put in process',
    message_error:'Message failed.',
    messsage_envoyer:'The message is sent successfully.',
    message_update:'The message is modified successfully.',
    Déconnexion:'Logout',
    MODIFICATION_DE_MOT_DE_PASSE:'CHANGE PASSWORD',
    Entez_les_infomations_pour_continuer:'Enter the information to continue',
    Mot_de_passe:'Password',
    Confirmation_mot_de_passe:'Password confirmation',
    veuillez_saisir_le_nouveau_mot_de_passe:'Please enter the new password',
    MOT_DE_PASSE_CHANGÉ:'PASSWORD CHANGED',
    ERROR_PASSWORD:'ERROR',
    Modifier_mon_mot_de_passe:'Change password',
    Ouvrez_une_session_pour_commencer_à_utiliser_application:'Log in to start using the application.',
    Se_souvenir_de_moi:'Remember me',
    Mot_de_passe_oublié:'Forgot your password?',
    Connexion:'Login',
    Récupérez_votre_mot_de_passe:'Recover your password',
    recupération:'Please enter your email address and we\'ll send you instructions to reset your password.',
    Changer:'Change',
    password_error:'The password field is required',
    messages:'Messages',
   
    
    cities:'Cities',
    city:'City',
    CITY:'CITY',

    diseases:'Diseases',
    disease:'Disease',
    DISEASE:'DISEASE',

    allergies:'Allergies',
    allergie:'Allergie',
    ALLERGIE:'ALLERGIE',

    operations:'Operations',
    operation:'Operation',
    OPERATION:'OPERATION',


    previousTreatments:'Previous treatments',
    previousTreatment:'Previous treatment',
    PREVIOUSTREATMENTS:'PREVIOU TREATMENT',

    partnerTypes:'Partners Types',
    partnerType:'Partner Type',
    PARTNERTYPES:'PARTNER TYPE',

    partners:'Partners',
    partner:'Partner',
    PARTNER:'PARTNER',

    appointments:'Appointments',
    appointment:'Appointments',
    APPOINTMENT:'APPOINTMENT',

    city_save:'The city is save successfully.',
    city_update:'The city is modified successfully.',
    city_error:'The city field is required',
    SuppressionCities:'The city is successfully deleted.',

    disease_save:'The disease is save successfully.',
    disease_update:'The disease is modified successfully.',
    disease_Suppression:'The disease is successfully deleted.',
    disease_error:'The disease field is required',
    description_error:'The description field is required',

    allergie_save:'The allergie is save successfully.',
    allergie_update:'The allergie is modified successfully.',
    allergie_error:'The allergie field is required',
    allergie_Suppression:'The allergie is successfully deleted.',

    previousTreatment_save:'The previous treatment is save successfully.',
    previousTreatment_update:'The previous treatment is modified successfully.',
    previousTreatment_error:'The previous treatment field is required',
    previousTreatment_Suppression:'The previous treatment is successfully deleted.',

    partnerType_save:'The partner type is save successfully.',
    partnerType_update:'The partner type is modified successfully.',
    partnerType_error:'The partner type is required',
    partnerType_Suppression:'The partner typvalidate_ratee is successfully deleted.',

    partner_save:'The partner is save successfully.',
    partner_update:'The partner is modified successfully.',
    partner_error:'The partner is required',
    partner_Suppression:'The partner is successfully deleted.',

    doctor:'Doctor',
    age:'Age',
    patient:'Patient',
    gender:'Gender',
    pattern:'Pattern',
    appointementDate:'Appointement date'
    /* new projet En */
  },

  fr: {
    Dashboard: 'Tableau de bord',
    Utilisateurs: 'Utilisateurs',
    Compagnies: 'Entreprises',
    Address:'Adresse',
    address:'Adresse',
    Date_creation:'Date de création',
    CFE :'CFE',
    Date_creation_error:'Le champ date de création est obligatoire',
    clients:'Clients',
    abonnements :'Abonnements',
    ABONNEMENTS  :'ABONNEMENT',
    Age :'Âge',
    Solutions: 'Solutions',
    Add: 'Ajouter',
    Incidents : 'Incidents',
    Tickets_totaux: 'Tickets totaux',
    Tickets_traité:'Tickets traités',
    Tickets_non_traités:'Tickets non traités',
    En_cours_de_traitement:'en cours de traitement',
    tickets_encours_traitement:' Tickets en traitement ',
    Créer_un_ticket:'Créer un ticket',
    Traiter:'Traité',
    Traiter1:'Traiter',
    Non_Traité:'Non traité',
    urgency:'Urgent',
    no_urgency:'Non urgent',
    Profil: 'Profil',
    PROFIL: 'PROFIL',
    Nom_Prenons: 'Nom & Prénoms',
    mail: 'E-mail',
    username:'Nom d\'utilisateur',
    Phone: 'Téléphone',
    Compagnie: 'Entreprise',
    Ajouter: 'Ajouter',
    AJOUTER_UN: 'AJOUTER UN',
    AJOUTER_UNE: 'AJOUTER UNE',
    MODIFICATION_DE_L: 'MODIFIER L\'',
    MODIFICATION_DE: 'MODIFIER LA',
    MODIFICATION_D: 'MODIFIER D\'UN',
    MODIFICATION_DU: 'MODIFIER LE',
    UTILISATEUR: 'UTILISATEUR',
    UTILISATEURS: 'Utilisateurs',
    Modifier: 'Modifier',
    Supprimer: 'Supprimer',
    Action: 'Action',
    Rôle: 'Rôle',
    Nom: 'Nom',
    Prénoms: 'Prénoms',
    Soumettre: 'Soumettre',
    Annuler: 'Annuler',
    Quit:'Quitter',
    enteteDelete: 'Confirmer la suppressions?',
    confir_upd:'Confirmer la mise à jour',
    Confirmer :'confirmer',
    SuppressionUser1:'L\'utilisateur est supprimé avec succès.',
    save_error:'Enrégistrement échoué.',
    save_sucess_user:'L\'utilisateur est enrégistré avec succès.',
    update_user:'L\'utilsateur est modifié avec succès.',
    last_name_error:  'Le champ nom est obligatoire',
    first_name_error: 'Le champ prénoms est obligatoire',
    role_error:  'Le champ role est obligatoire',
    email_error: 'Le champ e-mail est obligatoire',
    username_error: 'Le champ nom d\'utilisateur est obligatoire',
    phoneNumber_error:'Le champ téléphone est obligatoire',
    nom_error: 'Le champ nom est obligatoire',
    SuppressionClient:'La client est supprimée avec succès.',
    Address_error:'Le champ addresse est obligatoire',
    nametype_error:'Le champ incident est obligatoire',
    INCIDENT:'INCIDENT',
    incident:'Incidents',
    incident_update:'L\'incident est modifiée avec succès.',
    incident_save:'L\'incident est enrégistrée avec succès.',
    Solution:'Solution',
    abonnement_delete: 'L\'abonnement est supprimée avec succès.',
    error_abonnement: 'Le champ abonnement est obligatoire',
    abonnement_field_error:'Le champ abonnement est obligatoire',
    abonnement_save:'L\'abonnement est enrégistrée avec succès.',
    abonnement_update:'L\'abonnement est modifiée avec succès.',
    Company_delete :'L\'entreprise est supprimée avec succès.',
    resource_person:'Personne ressource',
    CFE_error:'Le champ CFE ressource est obligatoire',
   
    COMPAGNIE:'ENTREPRISE',
    non_lu : 'Non lu',
    lu :'Lu',
    actualiser:'Actualiser',
    voir:'Voir',
    Envoyer_un_mail:'Envoyer un mail',
    Envoyer:'Envoyer',
    Destinataire:'Destinataire',
    créer_un_ticket:'Créer un ticket',
    ticket_error:'Votre enrégistrement à échouer.',
    ticket_succes:'Ticket est créé avec succès.',
    Précédent:'Précédent',
    Suivant:'Suivant',
    Annuler_le_traitement_encours:'Annuler le traitement encours',
    Mettre_encours_de_traitement:'Mettre encours de traitement',
    message_error:'Le message à échouer.',
    messsage_envoyer:'Le message est envoyé avec succès.',
    message_update:'Le message est modifié avec succès.',
    Déconnexion:'Déconnexion',
    MODIFICATION_DE_MOT_DE_PASSE:'MODIFICATION DE MOT DE PASSE',
    Entez_les_infomations_pour_continuer:'Entez les infomations pour continuer',
    Mot_de_passe:'Mot de passe',
    Confirmation_mot_de_passe:'Confirmation mot de passe',
    veuillez_saisir_le_nouveau_mot_de_passe:'veuillez saisir le nouveau mot de passe',
    MOT_DE_PASSE_CHANGÉ:'MOT DE PASSE CHANGÉ',
    ERROR_PASSWORD:'ERREUR',
    Modifier_mon_mot_de_passe:'Modifier mon mot de passe',
    Ouvrez_une_session_pour_commencer_à_utiliser_application:'Ouvrez une session pour commencer à utiliser l\'application.',
    Se_souvenir_de_moi:'Se souvenir de moi',
    Mot_de_passe_oublié:'Mot de passe oublié?',
    Connexion:'Connexion',
    Récupérez_votre_mot_de_passe:'Récupérez votre mot de passe',
    recupération:'Veuillez saisir votre adresse e-mail et nous vous enverrons des instructions pour réinitialiser votre mot de passe.',
    Changer:'Changer',
    password_error:'Le champ mot de passe est obligatoire',
    messages:'Messages',
    Suppression:'Suppression échouée',
    detail_rendez_vous:'Détails du rendez-vous',
    totalFees:'Coût total',

    cities:'Villes',
    city:'Ville',
    CITY:'VILLE',
    diseases:'Maladies',
    disease:'Maladie',
    DISEASE:'MALADIE',


    allergies:'Allergies',
    allergie:'Allergie',
    ALLERGIE:'ALLERGIE',


    operations:'Opérations',
    operation:'Opération',
    OPERATION:'OPÉRATION',

    occupations:'Professions',
    occupation:'Profession',
    OCCUPATION:'PROFESSION',

    previousTreatments:'Traitements précédents',
    previousTreatment:'Traitement précédent',

    PREVIOUSTREATMENTS:'TRAITEMENT PRÉCÉDENT',
    
    partnerTypes:'Types de partenaire',
    partnerType:'Type de partenaire',
    PARTNERTYPES:'TYPES DE PARTENAIRE',
    PARTNERTYPES_a:'TYPE DE PARTENAIRE',


    pharmacies:'Pharmacies',
    pharmacy :'Pharmacie',
    PHARMACIES :'PHARMACIES',

    partners:'Assureurs',
    partner:'Assureur',
    PARTNER:'ASSUREUR',

    partners_p:'Partenaires',
    partner_p:'Partenaire',
    PARTNER_p:'PARTENAIRE',

    appointments:'Rendez-vous',
    appointment:'Rendez-vous',
    APPOINTMENT:'RENDEZ-VOUS',

    patients:'Patients',
    patient:'Patient',
    PATIENT:'PATIENT',

    roles:'Rôles',
    role:'Role',
    ROLE:'ROLE',
    
    typeActes :'Types actes',
    typeActe :'Type acte',
    TYPEACTE :'TYPE ACTE',

    actes:'Actes',
    acteS:'Acte(s)',
    acte:'Acte',
    couverture:'Couverture',
    ACTE :'ACTE',
    price :'Prix',

    treatments:'Traitements',
    treatment:'Traitement',
    TREATMENT:'TRAITEMENT',

    stocks:'Approvisionnements',
    stockP:'Approvisionnement',
    stock:'Nom de l\'approvisionnement',
    STOCK:'APPROVISIONNEMENT',
    
    stocks_List:'Liste d\'approvisionnements',
    stocks_stocks:'Stocks',
    stock_List:'Liste d\'approvisionnement',
    STOCK_List:'LISTE D\'APPROVISIONNEMENT',

    Stock_Title:'Stock',
    upStock: 'Entrée',
    downStock: 'Sortie',
    ReupStock: 'Entrée en stock',
    DEdownStock: 'Sortie de stock',
    Newquantity :'Nouvelle quantité',
    Dispoquantity:'Quantité disponible',

    stockTypes:'Types d\'approvisionnements',
    stockType:'Type d\'approvisionnement',
    STOCKTYPE:'TYPE D\'APPROVISIONNEMENT',

    products:'Produits',
    product:'Produit',
    PRODUCT:'PRODUIT',

    materials:'Matériels',
    material :'Matériel',
    MATERIAL :'MATÉRIEL',
    
    sickLeaves:'Certificats de repos',
    SICKLEAVES:'CERTIFICAT DE REPOS',
    entitled:'Intitulé',
    startDate:'Date de début',
    endDate:'Date de fin',
    reason:'Raison',
    additionalInformation:'Informations Complémentaires',

    Invoice:'Facture',
    addDate:'Date d\'ajout',
    UpDate:'Date de mise à jour',
    treatDate:'Date du traitement',

    dosage:'Dosage',
    indication:'Indication',
    routeOfAdministration:'Voie d\'administration',
    contraindication:'Contre-indication',
    sideEffects:'Effets secondaires',

    prescriptions:'Ordonnances',
    prescriptions_D:'Ordonnance(s)',
    prescription:'Ordonnance',
    PRESCRIPTION:'ORDINNANCE',
    durationOfTreatment:'Durée du traitement',
    Comment:'Commentaire',

    enters:'Entrées',
    outs:'Sorties',

    Période:'Période',
    day:'Nombre de jours',
    week:'Nombre de semaine',
    month:'Nombre de mois',
    day_error:'Le champ jours est obligatoire',
    week_error:'Le champ semaine est obligatoire',
    month_error:'Le champ mois est obligatoire',
    
    PAYER_UNE:'PAYER UNE',
    BILL:'FACTURE',
    bill:'Facture',
    bills_to_settle:'Factures tiers payants',
    Bills_to_settle:'FACTURE TIERS PAYANT',

    bills:'Factures',
    bills_not_paid:'Factures impayées',
    bills_paid:'Factures payées',
    paymentType:'Type de paiement',
    paymentMode:'Mode de reglement',
    totalToBePaid:'Total payé',
    paid:'Payer',
    not_paid:'Impayer',
    rate:'Taux de couverture',
    Type_reduction:'Type de réduction',
    totalAmount:'Montant total',
    totalpaid:'Montant à payer',
    PayDate: 'Date de paiement',

    amount:'Montant',
    assured:'Assurance',
    update_acte:'Modifier le taux',
    edit_acte :'Détails des actes et des taux',
    edit_acte_update :'Mise à jour',
    FacDate :'Date de la facture',
    save_product:'Enrégistrer un produit',
    setting:'Paramètres',
    sponsoring:'Partenaires',
    medical_doc:'Dossiers médicaux',
    soins_medical:'Soins médicaux',
  
    paymentMode_error:'Le champ mode de paiement  est obligatoire',
    paymentType_error:'Le champ type de paiement  est obligatoire',
    amount_error:'Le champ montant  est obligatoire',
    rate_error:'Le champ taux  est obligatoire',
    Type_reduction_error:'Le champ type de réduction  est obligatoire',
    facture_error:'Erreur de paiement',
    facture_save:'Paiement réussi avec succès.',
    amount_error_paid:'le montant de réduction est supérieur au montant total',
    rate_error_paid:'le taux appliqué est supérieur à 100',
    day_up_error:'le nombre de jours doit être supérieur à zéro (0)',
    week_up_error:'le nombre de semaines doit être supérieur à zéro (0)',
    month_up_error:'le nombre de mois doit être supérieur à zéro (0)',
    Bills_sttle_paid:'Facture payée par l\'assurance avec succès.',
    Bills_sttle_error:'Erreur de paiement',
    Confirmer_paid:'Confirmer le paiement',

    paraclinicalActivities_error:'Le champ acte paraclinique est obligatoire',
    periodeCheck_error:'Le champ périodes est obligatoire',
    SuppressionBill:'La facture  est supprimée avec succès.',
    
    prescriptions_update:'L\'ordonnance est modifiée avec succès.',
    prescriptions_error:'Le champ ordonnance  est obligatoire',
    prescriptions_save:'L\'ordonnance  est enrégistrée avec succès.',
    Suppressionprescriptions:'L\'ordonnance  est supprimée avec succès.',
    durationOfTreatment_error:'Le champ la durée du traitement est obligatoire',
    comment_error:'Le champ commentaire est obligatoire',


    sickLeaves_update:'Le congé de maladie est modifié avec succès.',
    sickLeaves_save:'Le congé de maladie est enrégistré avec succès.',
    SuppressionsickLeaves:'Le congé de maladie est supprimé avec succès.',
    entitled_error:'Le champ intitulé est obligatoire',
    startDate_error:'Le champ date de début est obligatoire',
    endDate_error:'Le champ date de fin est obligatoire',
    reason_error:'Le champ motif est obligatoire',
    additionalInformation_error:'Le champ information complémentaire est obligatoire',


    dosage_error:'Le champ dosage est obligatoire',
    indication_error:'Le champ indication est obligatoire',
    routeOfAdministration_error:'Le champ voie d\'administration est obligatoire',
    contraindication_error:'Le champ contre-indication est obligatoire',
    sideEffects_error:'Le champ effets secondaires est obligatoire',

    
    product_update:'Le produit est modifié avec succès.',
    product_error:'Le champ nom du produit est obligatoire',
    product_save:'Le produit est enrégistré avec succès.',
    Suppressionproduct:'Le produit est supprimé avec succès.',


    material_update:'Le material est modifié avec succès.',
    material_error:'Le champ nom du material est obligatoire',
    material_save:'Le material est enrégistré avec succès.',
    Suppressionmaterial:'Le material est supprimé avec succès.',

    stockType_update:'Le type d\'approvisionnement est modifié avec succès.',
    stockType_error:'Le champ nom type d\'approvisionnement est obligatoire',
    stockType_save:'Le type d\'approvisionnement est enrégistré avec succès.',
    SuppressionstockType:'Le type d\'approvisionnement est supprimé avec succès.',


    stock_update:'Entrée effectueé avec succès.',
    stock_downdate:'Sortie effectueé avec succès.',
    stock_faild:'Mise à jour échouéé',
    stock_error:'Le champ nom de l\'approvisionnement est obligatoire',
    stock_save:'L\'approvisionnement est enrégistré avec succès.',
    SuppressionStock:'L\'approvisionnement est supprimé avec succès.',
    quantity_error:'Le champ quantité est obligatoire',

    treatment_update:'Le traitement est modifié avec succès.',
    treatment_error:'Le champ traitement est obligatoire',
    treatment_save:'Le traitement est enrégistré avec succès.',
    SuppressionTreatment:'Le traitement est supprimé avec succès.',

    acte_update:'L\'acte est modifié avec succès.',
    acte_error:'Le champ acte est obligatoire',
    acte_save:'L\'acte est enrégistrée avec succès.',
    price_error:'Le champ prix est obligatoire',
    SuppressionActe:'L\'acte est supprimé avec succès.',

    typeActe_update:'Le type acte est modifié avec succès.',
    typeActe_error:'Le champ type acte est obligatoire',
    typeActe_save:'Le type acte est enrégistré avec succès.',
    SuppressionTypeActe:'Le type acte est supprimé avec succès.',

    city_update:'La ville est modifiée avec succès.',
    city_error:'Le champ ville  est obligatoire',
    city_save:'La ville est enrégistrée avec succès.',
    SuppressionCities:'La ville est supprimée avec succès.',

    disease_save:'La maladie est enrégistrée avec succès.',
    disease_update:'La maladie est modifiée avec succès.',
    disease_error:'Le champ nom de la maladie est obligatoire',
    disease_Suppression:'La maladie est supprimée avec succès.',
    description_error:'Le champ description est obligatoire',

    allergie_save:'L\'allergie est enrégistrée avec succès.',
    allergie_update:'L\'allergie est modifiée avec succès.',
    allergie_error:'Le champ nom de L\'allergie est obligatoire',
    allergie_Suppression:'L\'allergie est supprimée avec succès.',

    operation_save:'L\'operation enrégistrée avec succès.',
    operation_update:'L\'operation est modifiée avec succès.',
    operation_error:'Le champ nom de L\'opération est obligatoire',
    operation_Suppression:'L\'operation est supprimée avec succès.',  

    previousTreatment_save:'Traitements précédents enrégistré avec succès.',
    previousTreatment_update:'Traitements précédents modifié avec succès.',
    previousTreatment_error:'Le champ traitement précédent est obligatoire',
    previousTreatment_Suppression:'Traitements précédents supprimé avec succès.',

    occupation_save:'La profession enrégistrée avec succès.',
    occupation_update:'La profession est modifiée avec succès.',
    occupation_error:'Le champ profession est obligatoire',
    occupation_Suppression:'La profession est supprimée avec succès.',
    
    partnerType_save:'Le type assureur enrégistré avec succès.',
    partnerType_update:'Le type assureur est modifié avec succès.',
    partnerType_error:'Le champ type assureur est obligatoire',
    partnerType_Suppression:'Le type assureur est supprimé avec succès.',
    
    partner_save:'L\'assureur enrégistré avec succès.',
    partner_update:'L\'assureur est modifié avec succès.',
    partner_error:'Le champ assureur est obligatoire',
    partner_Suppression:'L\'assureur est supprimé avec succès.',

    appointement_save:'Le rendez-vous enrégistré avec succès.',
    appointement_update:'Le rendez-vous est modifié avec succès.',
    appointement_error:'Le champ date rendez-vous est obligatoire.',
    appointement_Suppression:'Le rendez-vous est supprimé avec succès.',

    patient_update:'Le patient est modifié avec succès.',
    patient_error:'Le champ patient  est obligatoire',
    patient_save:'Le patient est enrégistré avec succès.',
    patient_suppression:'Le patient est supprimé avec succès.',

    role_save:'Le rôlre est enrégistré avec succès.',
    role_update:'Le rôle est modifié avec succès.',
    role_Suppression:'Le rôle est supprimé avec succès.',

    titre_Ajouter_rendevous:'Ajouter un rendez-vous',
    titre_Ajouter_patient:'Ajouter un patient',
    titre_Modifier_patient:'Modifier un patient',
    detail_patient:'Détail patient',
    titre_modifier_supprimer_redevous:'Valider ou Modifier ou Supprimer un rendez-vous',
    doctor:'Docteur',
    age:'Âge',
    gender:'Sexe',
    pattern:'Motif',
    appointementDate:'Date de rendez-vous',
    medecine:'Médicament(s)',
    modifier:'Modifier',
    supprimer:'Supprimer',
    full_name:'Nom complet',
    valider:'valider',
    hours:'heures',
    otherDisease: 'Autre(s) maladie(s)',
    otherOperation: 'Autre(s) opéraion(s)',
    otherPreviousTreatment:'Autre(s) traitement(s) précédent(s)',
    otherAllergy:'Autre(s) Allergie(s)',
    quantity:'Quantité',
    stockTypesFilter:'Filtre par type d\'approvisionnement',

    insured:'Êtes vous assuré?',
    doYouSmoke:'Fumez vous?',
    healthyProblemThisYear:'Avez-vous eu un problème de santé cette année ?',
    hadAnOperation:'Avez-vous déjà subi une opération?',
    doYouDrinkAlcohol:'Buvez vous de l\'alcool?',
    previousTreatment_question:'Avez-vous déjà subi un précédent traitement?',
    doYouDrinkDrugs:'Prenez vous de la drogue ?',
    treatedInHospitalBefore:'Traité à l\'hôpital avant?',
    hadAnAllergy:'Avez vous une allergie?',
    pregnantOrFeeding:'Êtes vous enceinte ou  Allaitez vous?',
    takingMedecine:'Êtes-vous sous un traitement?',

    thirdPartyPayments:'Facture(s) tiers payant(s)',
    refFacture:'Référence',
    billNotPaid:'Facture(s) impayée(s)',
    billsPaid : 'Facture(s) payée(s)',
    pharmacies_d :'Pharmacie(s)',
    dentists : 'Praticien(s)',
    patients_D: 'Patient(s)',
    treatments_D:'Traitement(s)',
    assurances_D:'Assureur(s)',
    sickLeaves_D:'Certificat(s) de repos',
    Success : 'SUCCÈS',
    Failure :'ÉCHEC',
    practitioners: 'Praticiens',

    upcomingApointments: 'Rendez-vous à venir',
    pastdAppointments: 'Rendez-vous passés',
    etat :'État',

    marriageQuote:'Situation',
    full_name_of_partner:'Nom complet de partenaire',
    parentOrTutorFullName:'Nom complet du parent ou du tuteur',
    expirationDate:'Date d\'expiration',
    personToContactPhoneNumber:'Numéro de la personne à prevenir',
    personToContactName :'Nom de la personne à prevenir',
    fullNameAndPhoneNumnerOfDoctor:'Nom et numéro de téléphone du médecin',
    howmanyWeekOrMonth:'Nombre de semaines ou de mois',
    howDidYouKnowUs:'Comment vous nous avez connu?',
    consultationReason:'Raison de la consultation',

    etat_error:'Le champ état est obligatoire',
    age_error:'Le champ âge est obligatoire',
    gender_error:'Le champ sexe est obligatoire',
    marriageQuote_error:'Le champ situation est obligatoire',
    full_name_of_partner_error:'Le champ nom complet du partenaire est obligatoire',
    parentOrTutorFullName_error:'Le champ nom complet du parent ou du tuteur est obligatoire',
    expirationDate_error:'Le champ date d\'expiration est obligatoire',
    personToContactName_error:'Le champ nom de la personne à prevenir est obligatoire',
    personToContactPhoneNumber_error:'Le champ numéro de la personne à prevenir est obligatoire',
    fullNameAndPhoneNumnerOfDoctor_error:'Le champ nom et numéro de téléphone du médecin est obligatoire',
    medecine_error:'Le champ médicament est obligatoire',
    howDidYouKnowUs_error:'Le champ est obligatoire',
    consultationReason_error:'Le champ raison de la consultation est obligatoire',
    howmanyWeekOrMonth_error:'Le champ nombre de semaines ou de mois est obligatoire',
    doctor_error:'Le champ nom docteur est obligatoire',
    pattern_error:'Le champ motif est obligatoire',
    otherDisease_error: 'Le champ autre(s) maladie(s) est obligatoire',
    otherOperation_error: 'Le champ autre(s) opéraion(s) est obligatoire',
    otherPreviousTreatment_error:'Le champ autre(s) traitement(s) précédent(s) est obligatoire',
    otherAllergy_error:'Le champ autre(s) Allergie(s) est obligatoire',
    validate_appointment_success:'Rendez-vous validé avec succès',
    validate_appointment_error:'Rendez-vous non validé',
    Confirmer_validation:'Confirmer la validation',

    filte_rendezVous:'Filtre des rendez-vous',
    upcomingApointments_days:'Rendez-vous du jour',
    upcomingApointments_weeks:'Rendez-vous de la semaine',
    upcomingApointments_months:'Rendez-vous du mois',
    appointement_hours:'Vérifiez l\'horaire du rendez-vous.',
    date_systme:'Vérifiez bien la date de votre ordinateur.',
    /* new projet Fr */

    birthDate:'Date de naissaince',
    birthDate_error:'Le champ date de naissance est obligatoire',

    nationality:'Nationnalité',
    NATIONALITY:'NATIONALITE',
    nationalities:'Nationnalités',
    nationality_update:'La nationnalité est modifiée avec succès.',
    nationality_error:'La champ Nationnalité  est obligatoire',
    nationality_save:'La nationnalité est enrégistrée avec succès.',
    Suppressionnationality:'La nationnalité est supprimée avec succès.',

    country:'Pays',
    countries:'Pays',
    COUNTRY:'PAYS',
    country_update:'Le pays est modifié avec succès.',
    country_error:'Le champ pays  est obligatoire',
    country_save:'Le pays est enrégistré avec succès.',
    Suppressioncountry:'Le pays est supprimé avec succès.',

    jackpots:'Cagnottes',
    jackpot:'Cagnotte',
    JACKPOTS:'CAGNOTTE',
    jackpot_update:'La cagnotte est modifiée avec succès.',
    jackpot_error:'Le champ cagnotte  est obligatoire',
    jackpot_save:'La cagnotte est enrégistrée avec succès.',
    Suppressionjackpot:'La cagnotte est supprimée avec succès.',

    description:'Description',

    amountToAchieve_error:'Le champ montant  est obligatoire',
    displayParticipantsAmount:'Affiché le motant',
    displayParticipantsAmount_error:'Le champ affiché le motant est obligatoire',

    thematics:'Thématiques',
    thematic:'Thématique',
    THEMATIC:'THÉMATIQUE',
    thematic_update:'La thématique est modifiée avec succès.',
    thematic_error:'La thématique motif  est obligatoire',
    thematic_save:'La thématique est enrégistrée avec succès.',
    Suppressionthematic:'La thématique est supprimée avec succès.',

    Inscrits:'Inscrits',
    Localities:'Localités',

    phone_error:'Le champ téléphone est obligatoire',

    SuppressionPVD:'PVD supprimé avec succès.',
    PasswordReset:'Réinitialisation du mot de passe',

    agents:'Agents',
    
    regions:'Régions',
    region:'Région',
    REGIONS:'RÉGION',
    region_update:'La région est modifiée avec succès.',
    region_error:'Le champ région est obligatoire',
    region_save:'La région est enrégistrée avec succès.',
    Suppressionregion:'La région est supprimée avec succès.',

    branches:'Succursales',
    branche:'Succursale',
    BRANCHE:'SUCCURSALE',
    branche_update:'La succursale est modifiée avec succès.',
    branche_error:'Le champ succursale  est obligatoire',
    branche_save:'La succursale est enrégistrée avec succès.',
    Suppressionbranche:'La succursale est supprimée avec succès.',

    corporates:'Entreprises',
    corporate:'Entreprise',
    CORPORATE:'ENTREPRISE',
    corporate_error:'Le champ entreprise  est obligatoire',
    corporate_update : 'L\'entreprise est modifiée avec succès.',
    corporate_save: 'L\'entreprise est enrégistrée avec succès.',
    Suppressioncorporate:'L\'entreprise est supprimée avec succès.',

    denomination:'Dénomination',
    legalStatus:'Statut légal',
    email:'E-mail',
    activity:'Activité',
    numberOfEmployees:'Nombre d\'employés',
    identificationNumber:'NUMÉRO D\'IDENTIFICATION',

    cards:'Cartes',
    card:'Carte',
    CARD:'CARTE',
    card_error:'Le champ carte  est obligatoire',
    card_update : 'La carte est modifiée avec succès.',
    card_save: 'La carte est enrégistrée avec succès.',
    Suppressioncard:'La carte est supprimée avec succès.',
  
    dateOfIssue:'Date d\'émission',
    dateOfIssue_error:'Le champ date d\'émission est obligatoire',

    rateHealthyCenter:'Taux centres de santé',
    rateHealthyCenter_error:'Le champ taux centres de santé est obligatoire',

    rateClinic:'Taux cliniques',
    rateClinic_error:'Le champ taux cliniques est obligatoire',

    ratePharmacy:'Taux pharmacies',
    ratePharmacy_error:'Le champ taux pharmacies est obligatoire',

    rateMedicalOffice:'Taux cabinets',
    rateMedicalOffice_error:'Le champ taux cabinets est obligatoire',

    rateHospitals:'Taux hôpitaux',
    rateHospitals_error:'Le champ taux hôpitaux est obligatoire',

    birthdate:'Date de naissance',
    birthdate_error:'Le champ date de naissance est obligatoire',

    ownerOfPdv:'Inscrit',
    ownerOfPdv_error:'Le champ inscrit est obligatoire',

    name_file6:'Le champ photo est obligatoire'

  }
}
